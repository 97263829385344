
import InvitationBanner from '@components/invitation/invitation-banner.vue'

export const detectInvitationByToken = {
  components: {
    'invitation-banner': InvitationBanner
  },
  mounted () {
    if (this.token === '') {
      return
    }
    if (this.invitation === null || this.invitation === undefined) {
      this.$store.dispatch('fetchInvitation', { invitationId: this.token })
      return
    }

    if (this.invitation.token !== this.token) {
      this.$store.dispatch('fetchInvitation', { invitationId: this.token })
    }
  },
  computed: {

    showInvitationBanner () {
      return this.hasToken || this.hasInvitation
    },

    hasToken () {
      return this.token !== undefined
    },

    token () {
      return this.$route.query.token
    },

    invitation () {
      return this.$store.state.invitation.invitation
    },

    invitationErrorMessage () {
      return this.$store.state.invitation.invitationErrorMessage
    },

    invitationIsInvalid () {
      return !this.invitationIsValid
    },

    invitationIsValid () {
      return this.$store.state.invitation.invitationIsValid
    },

    hasInvitation () {
      return (this.invitation !== null)
    }
  }
}
