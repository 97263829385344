<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <div>
      <img
        src="@assets/img/logos/disguise_logo_RGB_White.svg"
        alt="Previz Logo"
        class="w-32 px-4 py-1 m-4 mx-auto md:w-48"
      >
      <h2
        class="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-200"
      >
        Sign in to your account
      </h2>
      <p class="max-w-sm mt-2 text-sm leading-5 text-center text-gray-400">
        Or
        <router-link
          :to="toJoin"
          class="font-medium text-indigo-500 transition duration-150 ease-in-out hover:text-indigo-400 focus:outline-none focus:underline"
        >
          get started for free
        </router-link>
      </p>

      <invitation-banner />

      <form
        class="px-4 py-8 mt-8 mb-4 bg-white rounded shadow sm:rounded-lg sm:px-10"
        :class="{ 'error': form.errors.get('form') }"
        @submit.prevent="onSubmit"
      >
        <div
          v-if="hasInvitation"
          class="px-2 mb-4"
        >
          <h2 class="text-lg">
            Login
          </h2>
          <h3 class="text-blue-500">
            Don't have an account?
            <router-link
              :to="toJoin"
              class="italic underline hover:text-blue-600"
            >
              Register &rarr;
            </router-link>
          </h3>
        </div>

        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Email</label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              v-model="form.email"
              type="email"
              name="email"
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>

        <div class="mt-6">
          <label
            for="password"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Password</label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              v-model="form.password"
              type="password"
              name="password"
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>

        <div
          v-if="form.errors.get('form')"
          class="mt-6 text-xs italic text-red-500"
        >
          <p>{{ form.errors.get('form') }}</p>
        </div>

        <div class="flex items-center justify-end mt-6">
          <button
            v-if="showSuccess"
            class="btn positive icon"
          >
            <fa-icon icon="check" />Logged in
          </button>
          <button
            v-else-if="form.busy"
            class="btn loading icon"
          >
            <fa-icon
              icon="spinner"
              spin
            />Loading
          </button>
          <button
            v-else
            class="btn"
            type="submit"
            :class="{ 'disabled': !formReadyToSumbit, 'primary': formReadyToSumbit }"
            @click.prevent="onSubmit"
          >
            <template v-if="invitation">
              Login & Review Invite
            </template>
            <template v-else>
              Login
            </template>
          </button>
        </div>
      </form>
      <div class="flex justify-between text-white signup-container signup-footer">
        <p class="flex-1 text-xs text-left">
          Don't have an account?
          <br>
          <router-link
            :to="toJoin"
            class="hover:text-blue-500"
          >
            Sign up for free
          </router-link>
        </p>
        <p class="flex-1 text-xs text-right">
          <router-link
            :to="toForgotten"
            class="hover:text-blue-500"
          >
            Forgot Password?
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
/* global SparkForm:true */
import {
  detectInvitationByToken
} from '@mixins/detect-invitation-by-token'

export default {
  name: 'AuthLogin',
  mixins: [detectInvitationByToken],
  data() {
    return {
      continueTo: '',
      toForgotten: {
        name: 'forgot',
        query: {
          token: this.token
        }
      },
      toJoin: {
        name: 'join',
        query: {
          token: this.token
        }
      },
      form: new SparkForm({
        email: '',
        password: '',
        token: this.token
      }),
      showSuccess: false
    }
  },
  computed: {

    formReadyToSumbit() {
      if (this.form.email === '' || this.form.password === '') {
        return false
      }
      return true
    },
    user: function () {
      return this.$store.getters.user
    },
    userEmail: function () {
      if (this.user === null || this.user === undefined) return ''
      return this.user.email
    }
  },
  created: function () {
    if (this.$route.query && this.$route.query.continueTo) {
      this.continueTo = this.$route.query.continueTo
    }
  },
  methods: {
    onSubmit() {
      if (this.form.email === '' || this.form.password === '') {
        this.form.setErrors({
          'form': ['Check the form details and try again']
        })
      } else {
        this.handleSubmit()
      }
    },

    handleSubmit() {
      this.form.startProcessing()

      this.$store.dispatch('auth/authUser', {
        email: this.form.email,
        password: this.form.password
      })
        .then(result => {
          this.showSuccess = true
          this.form.finishProcessing()
        })
        .catch(error => {
          this.showSuccess = false
          console.log(error)
          this.form.setErrors({
            'form': ['Sorry, login failed. Check the details and try again']
          })
        })
    }
  }
}

</script>
