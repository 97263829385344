<template>
  <div
    v-if="showBanner"
    class="bg-white text-gray-900 rounded-lg py-4 px-8 my-2"
  >
    <template v-if="invitationIsValid">
      <h3 class="text-xl mb-2">
        You've been invited to join a Previz team
      </h3>
      <p><span class="font-bold">{{ invitation.inviter_name }}</span> ({{ invitation.inviter_email }}) has invited you to <span class="font-bold">{{ invitation.team_name }}</span></p>
      <p class="text-sm text-gray-600">
        Login or create an account to accept this invite
      </p>
    </template>

    <template v-if="invitationIsInvalid">
      <h3 class="text-xl font-bold mb-2 text-red-500">
        <fa-icon
          :icon="['far', 'frown']"
          size="lg"
          class="mr-1 fa-fw"
        /> {{ invitationErrorMessage }}
      </h3>
      <div class="text-gray-800 mb-1">
        <p>Get in contact with the inviter for more help.</p>
      </div>
      <p class="text-blue-500 italic">
        Is this a mistake? <a
          href="mailto:support@previz.co"
          class="underline hover:text-blue-700"
        >Reach out to Previz Support</a> and we'll help
      </p>
    </template>
  </div>
</template>
<script>
export default {
  name: 'InvitationBanner',

  computed: {

    showBanner () {
      return this.hasInvitation || this.invitationErrorMessage !== null
    },

    isGuestRole () {
      return (this.invitation.options.role === 'guest_limited')
    },

    projects () {
      return this.invitation.options.accessProjects
    },

    invitation () {
      return this.$store.state.invitation.invitation
    },

    invitationErrorMessage () {
      return this.$store.state.invitation.invitationErrorMessage
    },

    invitationIsInvalid () {
      return !this.invitationIsValid
    },

    invitationIsValid () {
      return this.$store.state.invitation.invitationIsValid
    },

    hasInvitation () {
      return (this.invitation !== null)
    }
  }
}
</script>
